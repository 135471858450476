export const commonFiles = [
  {
    name: "Letter of Recommendation",
    required: true,
    fileCount: 1,
  },
  { name: "Letter of Recommendation", required: false, fileCount: 1 },
  { name: "PST Memo", required: true, fileCount: 1 },
  { name: "Resume", required: true, fileCount: 1 },
  { name: "Transcript", required: true, fileCount: 1 },
  { name: "Photo", required: true, fileCount: 1 },
  { name: "DD-2808", required: true, fileCount: 1 },
  { name: "DD-2807", required: true, fileCount: 1 },
];
export const rotcFiles = [
  { name: "Application Letter", required: true, fileCount: 1 },
  { name: "Duty Preference", required: true, fileCount: 1 },
  { name: "CO Endorsement Letter", required: true, fileCount: 1 },
];
export const ocsActiveDutyFiles = [
  { name: "CO Endorsement Letter", required: true, fileCount: 1 },
  { name: "Navy Eval #1", required: true, fileCount: 1 },
  { name: "Navy Eval #2", required: true, fileCount: 1 },
  { name: "Navy Eval #3", required: true, fileCount: 1 },
  { name: "Navy Eval #4", required: true, fileCount: 1 },
];
export const ocsCivilianFiles = [
  { name: "N3M letter From NRC", required: true, fileCount: 1 },
];

export const lateralTransferFiles = [
  { name: "Application Letter", required: true, fileCount: 1 },
  { name: "CO Endorsement Letter", required: true, fileCount: 1 },
];
export const interServiceTransferFiles = [
  { name: "Application Letter", required: true, fileCount: 1 },
  { name: "CO Endorsement Letter", required: true, fileCount: 1 },
  { name: "DD-368", required: true, fileCount: 1 },
  { name: "Fitness report", required: true, fileCount: 1 },
  { name: "Personnel Record", required: true, fileCount: 1 },
];
export const interAcademyTransferFiles = [
  { name: "Application Letter", required: true, fileCount: 1 },
  { name: "CO Endorsement Letter", required: true, fileCount: 1 },
  { name: "DD-368", required: true, fileCount: 1 },
];
export const navalAcademyFiles = [
  { name: "Mid performance record", required: true, fileCount: 1 },
];
