export const academyOptions = [
  { label: "Naval Academy", value: "naval-academy" },
  { label: "West Point", value: "west-point" },
  { label: "Air Force Academy", value: "air-force-academy" },
  { label: "Coast Guard Academy", value: "coast-guard-academy" },
  { label: "Merchant Marine Academy", value: "merchant-marine-academy" },
];
export const OCSOptions = [
  { label: "Active Duty", value: "active-duty" },
  { label: "Active Duty SEAL", value: "active-duty-seal" },
  { label: "Civilian", value: "civilian" },
];
export const serviceOptions = [
  { label: "Army", value: "army" },
  { label: "Air Force", value: "air-force" },
  { label: "Coast Guard", value: "coast-guard" },
  { label: "Merchant Marine", value: "merchant-marine" },
];
export const accessionSources = [
  { label: "Academy", value: "academy" },
  { label: "OCS", value: "ocs" },
  { label: "STA-21/ROTC", value: "rotc" },
  { label: "Lateral Transfer", value: "lateral-Transfer" },
  { label: "Inter-Service Transfer", value: "inter-Service-Transfer" },
];
