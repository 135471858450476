import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./elements/table";
import { Input } from "./elements/input";
import {createEmploymentHistory} from "../graphql/mutations";
import { InputStateWork } from "../constants/interface";
import { UserIdProps} from "../constants/props";
import { Button } from "./elements/button";
import { generateClient } from "aws-amplify/api";


const EmploymentHistory: React.FC<UserIdProps> = ({ userId }) => {
  const client = generateClient();

  
  const [userID, setUserID] = useState<string>("");

  const [workHistory, setWorkHistory] = useState<InputStateWork[]>([
    // Default state for the first row
    {
    
    usersID: "",
    companyName: "",
    titleOrPosition: "",
    startDate:  "",
    endDate:  "",
    reasonForLeaving: ""
    },
  ]);
  const [rowCount, setRowCount] = useState<number>(1);
  const [selectedStartDate, setSelectedStartDate] = useState<{
    [id: string]: string;
  }>({});

  const [selectedStartDateTwo, setSelectedStartDateTwo] = useState<{
    [id: string]: string;
  }>({});

  const createNewWorkHistory = async (index: number, usersID: string) => {
    try {
      const newWorkHistory = await client.graphql({
        query: createEmploymentHistory,
        variables: {
          input: {
            
            usersID: userID,
            companyName: workHistory[index].companyName,
            titleOrPosition: workHistory[index].titleOrPosition,
            startDate: workHistory[index].startDate,
            endDate: workHistory[index].endDate,
            reasonForLeaving: workHistory[index].reasonForLeaving
          },
        },
      });
      console.log("New Work history created:", newWorkHistory);
    } catch (error) {
      console.error("Error creating new Work History:", error);
    }
  };

  const handleAddRow = (event: React.MouseEvent) => {
    event.preventDefault();
    setRowCount((prevRowCount) => prevRowCount + 1);

    setWorkHistory((prevWorkHistory) => [
      ...prevWorkHistory,
      {
        employmentHistory: "",
        usersID: "",
        companyName: "",
        titleOrPosition: "",
        startDate: "",
        endDate: "",
        reasonForLeaving: "", // Add the missing property
      },
    ]);
  };

  const handleRemoveRow = (event: React.MouseEvent) => {
    event.preventDefault();
    if (rowCount > 1) {
      setRowCount((prevRowCount) => prevRowCount - 1);
  
      // Remove the last entry from selectedActivity
      const newSelectedActivity = { ...selectedStartDate };
      delete newSelectedActivity[`StarteDate${rowCount - 1}`];
      setSelectedStartDate(newSelectedActivity);

      const newSelectedActivityTwo = { ...selectedStartDateTwo };
      delete newSelectedActivityTwo[`EndDate${rowCount - 1}`];
      setSelectedStartDateTwo(newSelectedActivityTwo);

      // Remove the last row from xC
      setWorkHistory((prevWorkHistory) => prevWorkHistory.slice(0, -1));
    }
  };


  const handleInputChange = (index: number, property: string, value: string) => {
    setWorkHistory((prevWorkHistory) =>
    prevWorkHistory.map((WorkInput, i) =>
        i === index
          ? { ...WorkInput, [property]: value }
          : WorkInput
      )
    );
  };

  const handleSaveData = async (event: React.MouseEvent) => {
    event.preventDefault(); // Prevent the default form submission behavior

    // Use Promise.all to wait for all asynchronous calls to complete

    await Promise.all(
      workHistory.map((rowData, index) => createNewWorkHistory(index, userID))
    );

    // Additional logic after all calls have completed, if needed
  };
  useEffect(() => {
    setUserID(userId);
  });
  return (
    <div className="pb-200">
      <h2 className="text-base font-semibold leading-7 text-gray-900">
        Employment History
      </h2>
      <p className="mt-1 text-sm leading-6 text-gray-600">
        Input data from your prior professional jobs.
      </p>
      <div className="flex space-x-2">
        <Button
          onClick={handleAddRow}
          className="px-2 py-1 bg-green-700 text-white rounded-md hover:bg-green-800"
        >
          Add Company
        </Button>
        <button
          onClick={handleRemoveRow}
          className="px-2 py-1 bg-red-500 text-white rounded-md hover:bg-red-600"
        >
          Remove Company
        </button>
      </div>
      <Table>
        <TableCaption>A list of your employment history</TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[240px]">Company</TableHead>
            <TableHead>Job Title</TableHead>
            <TableHead>Start Date</TableHead>
            <TableHead>End Date</TableHead>
            <TableHead>Reason for leaving</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {[...Array(rowCount)].map((_, index) => (
            <TableRow key={index}>
              <TableCell className="font-medium">
                <Input
                  id={`companyName${index}`}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(index, "companyName", e.target.value)}
                />
              </TableCell>
              <TableCell>
                
                <Input
                  id={`titleOrPosition${index}`}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(index, "titleOrPosition", e.target.value)}
                />
              </TableCell>
              <TableCell>
              
                <Input
                  id={`startDate${index}`}
                  type="date"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(index, "startDate", e.target.value)}
                />
              </TableCell>
              <TableCell>
        
                <Input
                  id={`endDate${index}`}
                  type="date"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(index, "endDate", e.target.value)}
                />
              </TableCell>
              <TableCell>
                
                <Input
                  id={`departure-reason${index}`}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(index, "reasonForLeaving", e.target.value)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className="flex justify-end">
        <button
          onClick={handleSaveData} //TODO: Add mutation
          className="px-2 py-1 bg-green-500 text-white rounded-md hover:bg-green-600"
        >
          Save employment info
        </button>
      </div>
    </div>
  );
};

export default EmploymentHistory;
