import React from "react";
import { useState } from "react";
import logo from "../assets/TridentReadyBlackLogo.svg";
import { NAVIGATION_LINKS } from "../constants";
import { Link } from "react-router-dom";
import "../styling/NavStyles.css";
const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const goldLinkIndex = 0; // Change this to the desired index
  return (
    <nav
      className="mx-auto flex max-w-7xl items-center justify-between py-6 lg:px-8 navbar w-full bg-black"
      aria-label="Global"
     
    >
      <div className="flex lg:flex-1">
        <img src={logo} alt="Logo" className="w-[auto] h-[20px]" />
        <ul className="list-none sm:flex hidden justify-end items-center flex-1 custom-font2">
  {NAVIGATION_LINKS.map((nav, index) => (
    <li key={nav.label} className="mr-6">
      <a
        href={nav.path}
        className={`text-base font-medium ${
          index === goldLinkIndex ? 'text-gold' : 'text-gray-500'
        } hover:text-white`}
      >
        {nav.label}
      </a>
    </li>
  ))}
</ul>
        <i onClick={toggle} className="fas fa-bars burger"></i>
      </div>
    </nav>
  );
};

export default NavBar;
