import React, { useState, useEffect, ChangeEvent } from "react";
import { generateClient } from "aws-amplify/api";
import { createUsers } from "../graphql/mutations";
import { UserIdProps } from "../constants/props";
import { InputStateUsers } from "../constants/interface";
import { Input } from "./elements/input";
interface ContactInfoProps {
  userId: string;
}

const ContactInfo: React.FC<UserIdProps> = ({ userId }) => {
  const client = generateClient();

  const [userID, setUserID] = useState<string>("");

  const [firstName, setFirstName] = useState<string>("");
  const [middleName, setMiddleName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [DOB, setDOB] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [measureH, setMeasureH] = useState<number>(0);
  const [measureW, setMeasureW] = useState<number>(0);

  const handleInputChange = (
    property: string,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    switch (property) {
      case "firstName":
        setFirstName(e.target.value);
        break;
      case "middleName":
        setMiddleName(e.target.value);
        break;
      case "lastName":
        setLastName(e.target.value);
        break;
      case "email":
        setEmail(e.target.value);
        break;
      case "phoneNumber":
        setPhoneNumber(e.target.value);
        break;
      case "DOB":
        setDOB(e.target.value);
        break;
      case "measureH":
        setMeasureH(Number(e.target.value));
        break;
      case "measureW":
        setMeasureW(Number(e.target.value));
        break;
      default:
        break;
    }
  };

  // Event handlers

  // Get the user ID from the current user

  const handleSaveData = async (event: React.MouseEvent) => {
    event.preventDefault(); // Prevent the default form submission behavior
    //save data
    await createNewUser(0, userID);
  };

  const createNewUser = async (index: number, usersID: string) => {
    try {
      const newUser = await client.graphql({
        query: createUsers,
        variables: {
          input: {
            firstName: firstName,
            middleName: middleName,
            lastName: lastName,
            emailAddress: email,
            phoneNumber: phoneNumber,
            DOB: DOB,
            measureHeight: measureH,
            measureWeight: measureW,
          },
        },
      });
      // Handle the newUser data here
      console.log("New Personal Information created:", newUser);
    } catch (error) {
      console.error("Error creating new Personal Information:", error);
    }
  };

  useEffect(() => {
    setUserID(userId);
  }, [userId]);
  return (
    <fieldset>
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Personal Information
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Use a permanent address where you can receive mail.
          </p>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-2">
              <label
                htmlFor="given-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                First name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="given-name"
                  id="given-name"
                  autoComplete="given-name"
                  value={firstName}
                  onChange={(e) => handleInputChange("firstName", e)} // Use the generic function
                  className="block w-full px-4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="additional-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Middle name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="additional-name"
                  id="additional-name"
                  autoComplete="additional-name"
                  value={middleName}
                  onChange={(e) => handleInputChange("middleName", e)} // Use the generic function
                  className="block w-full px-4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="family-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Last name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  id="family-name"
                  name="family-name"
                  autoComplete="family-name"
                  value={lastName}
                  onChange={(e) => handleInputChange("lastName", e)} // Use the generic function
                  className="block w-full px-4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="email"
                  id="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => handleInputChange("email", e)} // Use the generic function
                  className="block w-full px-4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="birthday"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                DOB
              </label>
              <div className="mt-2">
                <Input
                  id="birthday"
                  type="date"
                  name="birthday"
                  autoComplete="birthday"
                  value={DOB}
                  onChange={(e) => handleInputChange("DOB", e)}
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="weight"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Weight (in pounds)
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  name="weight"
                  id="weight"
                  value={measureW}
                  onChange={(e) => handleInputChange("measureW", e)} // Use the generic function
                  className="block w-full px-4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="height"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Height (in inches)
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  name="height"
                  id="height"
                  value={measureH}
                  onChange={(e) => handleInputChange("measureH", e)} // Use the generic function
                  className="block w-full px-4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="tel"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Phone Number
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="tel"
                  id="tel"
                  autoComplete="tel"
                  value={phoneNumber}
                  onChange={(e) => handleInputChange("phoneNumber", e)} // Use the generic function
                  className="block w-full px-4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="flex justify-end col-span-6">
              <button
                onClick={handleSaveData} //TODO: Add mutation
                className="px-2 py-1 bg-green-500 text-white rounded-md hover:bg-green-600"
              >
                Save personal info
              </button>
            </div>
            <div className="sm:col-span-3"></div>
          </div>
        </div>
      </div>
    </fieldset>
  );
};

export default ContactInfo;
