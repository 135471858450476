import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./elements/table";
import { Input } from "./elements/input";
import { Button } from "./elements/button";
import { createEducation } from "../graphql/mutations";
import { generateClient } from "aws-amplify/api";
import { ComboBoxTwo } from "./elements/comboBoxTwo";
import { degreeOptions } from "../constants/index";
import { InputStateS } from "../constants/interface";
import { UserIdProps } from "../constants/props";

const ScholasticHistoryInfo: React.FC<UserIdProps> = ({ userId }) => {
  const client = generateClient();

  const [userID, setUserID] = useState<string>("");

  const [scholasticHistory, setScholasticHistory] = useState<InputStateS[]>([
    // Default state for the first row
    {
      usersID: "",
      schoolName: "",
      city: "",
      state: "",
      degree: "", // Update property name to "degree"
      major: "",
      gpa: 0,
      gpaScale: 0,
    },
  ]);

  const [selectedDegrees, setSelectedDegrees] = useState<{
    [id: string]: string;
  }>({});

  const [rowCount, setRowCount] = useState<number>(1);

  const createNewScholasticHistory = async (index: number, usersID: string) => {
    try {
      const newExtraCurricula = await client.graphql({
        query: createEducation,
        variables: {
          input: {
            collegeName: scholasticHistory[index].schoolName,
            collegeCity: scholasticHistory[index].city,
            collegeState: scholasticHistory[index].state,
            degreeRecieved: scholasticHistory[index].degree,
            majorDegree: scholasticHistory[index].major,
            gpaDegree: scholasticHistory[index].gpa,
            gpaScore: scholasticHistory[index].gpaScale,
            usersID: userID,
          },
        },
      });

      console.log("New scholasticHistory created:", newExtraCurricula);
    } catch (error) {
      console.error("Error creating new scholasticHistory:", error);
    }
  };

  const handleAddRow = (event: React.MouseEvent) => {
    event.preventDefault();
    setRowCount((prevRowCount) => prevRowCount + 1);

    setScholasticHistory((prevScholastic) => [
      ...prevScholastic,
      {
        usersID: userID,
        schoolName: "",
        city: "",
        state: "",
        degree: "",
        major: "",
        gpa: 0,
        gpaScale: 0,
      },
    ]);
  };

  const handleRemoveRow = (event: React.MouseEvent) => {
    event.preventDefault();
    if (rowCount > 1) {
      setRowCount((prevRowCount) => prevRowCount - 1);

      // Remove the last entry from selectedActivity
      const newSelectedActivity = { ...selectedDegrees };
      delete newSelectedActivity[`MajorLevel${rowCount - 1}`];
      setSelectedDegrees(newSelectedActivity);

      // Remove the last row from xC
      setScholasticHistory((prevScholastic) => prevScholastic.slice(0, -1));
    }
  };

  const handleComboxChangeOne = (index: number, id: string, value: string) => {
    setSelectedDegrees((prevDegrees) => ({
      ...prevDegrees,
      [id]: value,
    }));

    setScholasticHistory((prevScholastic) =>
      prevScholastic.map((ScholasticInput, i) =>
        i === index ? { ...ScholasticInput, degree: value } : ScholasticInput
      )
    );
  };

  const handleInputChange = (
    index: number,
    property: string,
    value: string
  ) => {
    setScholasticHistory((prevScholastic) =>
      prevScholastic.map((ScholasticInput, i) =>
        i === index
          ? { ...ScholasticInput, [property]: value }
          : ScholasticInput
      )
    );
  };
  const handleSaveData = async (event: React.MouseEvent) => {
    event.preventDefault(); // Prevent the default form submission behavior

    // Use Promise.all to wait for all asynchronous calls to complete

    await Promise.all(
      scholasticHistory.map((rowData, index) =>
        createNewScholasticHistory(index, userID)
      )
    );

    // Additional logic after all calls have completed, if needed
  };
  useEffect(() => {
    setUserID(userId);
  });
  return (
    <fieldset className="pb-200">
      <h2 className="text-base font-semibold leading-7 text-gray-900">
        Scholastic History
      </h2>
      <p className="mt-1 text-sm leading-6 text-gray-600">
        Input data from your college/university history.
      </p>
      <div className="flex space-x-2">
        <Button
          onClick={handleAddRow}
          className="px-2 py-1 bg-green-700 text-white rounded-md hover:bg-green-800"
        >
          Add School
        </Button>
        <button
          onClick={handleRemoveRow}
          className="px-2 py-1 bg-red-500 text-white rounded-md hover:bg-red-600"
        >
          Remove School
        </button>
      </div>
      <Table>
        <TableCaption>A list of universities you've attended</TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[240px]">College/University</TableHead>
            <TableHead>City</TableHead>
            <TableHead>State</TableHead>
            <TableHead>Degree</TableHead>
            <TableHead>Major</TableHead>
            <TableHead>GPA</TableHead>
            <TableHead>Scale</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {[...Array(rowCount)].map((_, index) => (
            <TableRow key={index}>
              <TableCell className="font-medium">
                <Input
                  id={`schoolName${index}`}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(index, "schoolName", e.target.value)
                  }
                />
              </TableCell>
              <TableCell>
                <Input
                  id={`city${index}`}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(index, "city", e.target.value)
                  }
                />
              </TableCell>
              <TableCell>
                <Input
                  id={`state${index}`}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(index, "state", e.target.value)
                  }
                />
              </TableCell>
              <TableCell>
                <Input />
              </TableCell>
              <TableCell>
                <ComboBoxTwo
                  index={index} // Pass the index to the Combobox
                  id={`participationLevel${index}`}
                  options={degreeOptions}
                  value={selectedDegrees[`participationLevel${index}`] || ""}
                  onChange={(id: string, index: number, value: string) =>
                    handleComboxChangeOne(index, id, value)
                  } // Adjust the onChange to pass index
                  placeholder="I.E Bachelors"
                />
              </TableCell>
              <TableCell>
                <Input
                  id={`gpa${index}`}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(index, "gpa", e.target.value)
                  }
                />
              </TableCell>
              <TableCell>
                <Input
                  id={`gpaScale${index}`}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(index, "gpaScale", e.target.value)
                  }
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className="flex justify-end">
        <button
          onClick={handleSaveData} //TODO: Add mutation
          className="px-2 py-1 bg-green-500 text-white rounded-md hover:bg-green-600"
        >
          Save scholarly info
        </button>
      </div>
    </fieldset>
  );
};

export default ScholasticHistoryInfo;
