export const NAVIGATION_LINKS = [
 
  { label: "Login", path: "/Login" },
  { label: "FAQ", path: "/FAQ" },
  { label: "Apply", path: "/Apply" },
  { label: "About", path: "/About" },
];

export const FOOTER_LINKS = [
  { label: "Privacy Policy", path: "/privacy" },
  { label: "Terms of Service", path: "/terms" },
  { label: "About Us", path: "/about" },
];



export const degreeOptions = [
  { label: "BA", value: "ba" },
  { label: "BS", value: "bs" },
  { label: "MA", value: "ma" },
  { label: "MS", value: "ms" },
  { label: "JD", value: "jd" },
  { label: "MD", value: "md" },
  { label: "PhD", value: "phd" },
];

export const MinOptions = [
  { label: "5 min", value: "5" },
  { label: "6 min", value: "6" },
  { label: "7 min", value: "7" },
  { label: "8 min", value: "8" },
  { label: "9 min", value: "9" },
  { label: "10 min", value: "10" },
  { label: "11 min", value: "11" },
];

