import React, { useState } from "react";
import { signUp, confirmSignUp, signIn } from "aws-amplify/auth";

import backgroundContour from "../assets/Background.jpg";
import {
  MainContainer,
  BoxL,
  BoxR,
  OperatorImage,
  Title,
  TimeLineTitle,
} from "../styling/LoginElements";

import "../styling/LoginStyles.css";

import myImage from "../assets/operator2.jpg";
const LandingPage = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [verificationStage, setVerificationStage] = useState<string>("create");
  const [error, setError] = useState<string>("");

  const handleSignUp = async (): Promise<void> => {
    setError(""); // Clear any previous errors

    // Check if passwords match
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      await signUp({
        username: email,
        password,
        options: {
          userAttributes: {
            email,
          },
        },
      });

      console.log("Account created successfully!");
      setVerificationStage("verify"); // Move to the verification stage
    } catch (error) {
      console.error("Error creating account:", error);
      setError("An error occurred during account creation.");
    }
  };

  const handleVerification = async (): Promise<void> => {
    try {
      await confirmSignUp({
        username: email,
        confirmationCode: verificationCode,
      });
      setVerificationStage("success"); // Verification successful
    } catch (error) {
      console.error("Error verifying email:", error);
      setError("Verification code is incorrect.");
    }
  };

  const handleSignIn = async (): Promise<void> => {
    setError(""); // Clear any previous errors

    try {
      await signIn({ username: email, password });
      // Handle successful sign-in (you can add a redirect here)
      console.log("Sign-in successful!");
    } catch (error) {
      console.error("Error signing in:", error);
      setError("Incorrect email or password.");
    }
  };

  const handleForgotPassword = async (): Promise<void> => {
    setError(""); // Clear any previous errors

    try {
      // Implement your own logic for forgot password if needed
      console.log("Forgot password initiated.");
    } catch (error) {
      console.error("Error initiating forgot password:", error);
      setError("Error initiating forgot password.");
    }
  };

  return (
    <div>
      <MainContainer>
        <BoxL>
          <Title>NAVY SEAL OFFICER</Title>
          <OperatorImage src={myImage} alt="arrow" />
        </BoxL>
        <BoxR>
          <TimeLineTitle className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            STEP 1:
            <br /> Create Account to Begin Application
          </TimeLineTitle>
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 ">
              <div>
                {verificationStage === "create" && (
                  <>
                    <div className="mt-1">
                      <label
                        htmlFor="email-address"
                        className="block text-sm  text-gray-700 font-medium custom-font"
                      >
                        Email address
                      </label>
                      <input
                        id="email-address"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm mt-1 custom-font2 "
                        placeholder="Email address"
                        style={{ fontWeight: "lighter" }}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    <div className="mt-6">
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700 custom-font"
                      >
                        Password
                      </label>
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm custom-font2"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>

                    <div className="mt-6">
                      <label
                        htmlFor="confirmPassword"
                        className="block text-sm font-medium text-gray-700 custom-font"
                      >
                        Confirm Password
                      </label>
                      <input
                        id="confirmPassword"
                        name="confirmPassword"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm custom-font2"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </div>
                  </>
                )}

                {verificationStage === "verify" && (
                  <>
                    <label className="custom-font2">Verification Code:</label>
                    <input
                      type="text"
                      value={verificationCode}
                      onChange={(e) => setVerificationCode(e.target.value)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm mt-1 custom-font2"
                      placeholder="Verification Code"
                    />
                  </>
                )}

                {error && <p style={{ color: "red" }}>{error}</p>}
                <div className="mt-6">
                  {verificationStage === "create" ? (
                    <>
                      {/* Use handleSignUp for the initial sign-up */}
                      <button
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 custom-font"
                        onClick={handleSignUp}
                      >
                        CREATE ACCOUNT
                      </button>
                    </>
                  ) : verificationStage === "verify" ? (
                    <>
                      {/* Use handleVerification for verification */}
                      <button
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 custom-font"
                        onClick={handleVerification}
                      >
                        Verify
                      </button>
                    </>
                  ) : (
                    <>
                      {/* Render a message when verification is successful */}
                      Thank You for Signing Up
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </BoxR>
      </MainContainer>
      <MainContainer>
        <BoxL>
          <TimeLineTitle>TIMELINE</TimeLineTitle>
          <div className="mx-auto py-5 max-w-5 xl">
            <ol className="relative border-s border-gray-200 dark:border-gray-700">
              <li className="mb-10 ms-4">
                <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                  1 - OCT - 23
                </time>
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Application window opens for SEAL officer candidates
                </h3>
                <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                  See the Official Navy HR website for further information
                </p>
                <a
                  href="https://www.mynavyhr.navy.mil/Career-Management/Community-Management/Officer/Active-OCM/Unrestricted-Line/Special-Warfare-OCM/SEAL-Officer-Selection/"
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-200 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-700"
                >
                  Learn more{" "}
                  <svg
                    className="w-3 h-3 ms-2 rtl:rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M1 5h12m0 0L9 1m4 4L9 9"
                    />
                  </svg>
                </a>
              </li>
              <li className="mb-10 ms-4">
                <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                  23 - FEB - 24
                </time>
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Applications are due for personnel attending BUD/s
                </h3>
                <p className="text-base font-normal text-gray-500 dark:text-gray-400">
                  All of the pages and components are first designed in Figma
                  and we keep a parity between the two versions even as we
                  update the project.
                </p>
              </li>
              <li className="ms-4">
                <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                  15 - JUN - 24
                </time>
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Applications are due for Active Duty SEALs attending OCS
                </h3>
              </li>
            </ol>
          </div>
        </BoxL>
        <BoxR> </BoxR>
      </MainContainer>
    </div>
  );
};

export default LandingPage;
