// Combobox.tsx
import React from "react";
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "./utils";
import { Button } from "./button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "./command";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";

interface ComboboxItem {
  value: string;
  label: string;
}

interface ComboboxProps {
  index: number; // Add index to ComboboxProps
  id: string;
  options: ComboboxItem[];
  value: string;
  onChange: (id: string, index: number, value: string) => void; // Include index in onChange
  onInternalChange?: (index: number, value: string) => void; // Optional internal change handler with index
  placeholder: string;
}

export function ComboBoxTwo({
  index, // Include index in the component props
  id,
  options,
  value,
  onChange,
  onInternalChange,
  placeholder,
}: ComboboxProps) {
  const [open, setOpen] = React.useState(false);

  const handleOptionSelect = (currentValue: string) => {
    onInternalChange && onInternalChange(index, currentValue); // Call internal change handler with index
    onChange(id, index, currentValue === value ? "" : currentValue); // Call external change handler with index
    setOpen(false);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[200px] justify-between"
        >
          {value
            ? options.find((option) => option.value === value)?.label
            : placeholder}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput
            placeholder={`Search ${placeholder.toLowerCase()}...`}
          />
          <CommandEmpty>No option found.</CommandEmpty>
          <CommandGroup>
            {options.map((option) => (
              <CommandItem
                key={option.value}
                value={option.value}
                onSelect={handleOptionSelect} // Pass the function directly
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    value === option.value ? "opacity-100" : "opacity-0"
                  )}
                />
                {option.label}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
