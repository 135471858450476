import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";

const faqs = [
  {
    question: "How many times am I eligible to apply to become a SEAL officer?",
    answer:
      "Applicants can be reviewed by the SEAL Officer Selection Panel two times. This includes applicants who only conduct an interview as part of their transfer package. If an applicant applies and is not invited to SOAS, this does not count as an attempt. Applicants who are medically disqualified at SOAS are eligible to apply a third time. All other applicants who have already been reviewed by the SEAL Officer Selection Panel two times should contact the OCM office to determine waiver eligibility.",
  },
  {
    question:
      "What are the age limitations for being commissioned as a Navy officer assigned to SEAL training?",
    answer:
      "Applicants must be at least 19 years of age and commissioned before their 42nd birthday at time of commissioning. ",
  },
  {
    question:
      "Can I give up my officer commission and join Naval Special Warfare as an enlisted SEAL?",
    answer:
      "No, per Navy Recruiting Manual-Enlisted, Section 3N-8: “Recruiters may not solicit former officers of the Armed Forces to enlist in the Navy, and former officers will not be approved should they volunteer.  However, an individual is considered non-prior service and eligible for enlistment if their appointment was terminated by an entry level separation in an enlisted status from Officer Candidate School, ROTC or one of the Service Academies. In some cases these recruits may have received an RE-4. CNRC may waive RE-4 codes restrictions in this case.”",
  },
  {
    question:
      "Is there a service obligation if I apply for Officer Candidate School (OCS) and follow-on SEAL training but am not selected by the board?",
    answer:
      "If you apply only for 1180 SEAL training and are not selected by the board you do not have a service obligation.",
  },
  {
    question:
      "Is there a service obligation if I am commissioned after completing Officer Candidate School (OCS) but fail to complete Basic Underwater Demolition/SEAL (BUD/S) training?",
    answer:
      "Yes, once you are a commissioned officer in the Navy you have a service obligation, although the Navy determines, based on needs, whether to re-designate you into a new community, or discharge you from the service.",
  },
  {
    question:
      "I am an Army/Air Force/Marine/Coast Guard officer in the Inactive Ready Reserve (IRR).  Can I apply for an inter-service transfer into the Naval Special Warfare (NSW) community?",
    answer:
      "No, you must be on active duty to apply for an inter-service transfer.",
  },
  {
    question:
      "I am a Naval Reserve Officer currently part of another warfare community (SWO, Aviation, etc.). Can I apply for a lateral transfer into the Naval Special Warfare (NSW) community?",
    answer: "No, you must be on active duty to apply for a lateral transfer.",
  },
  {
    question:
      "I want to apply to become a SEAL officer but my eyes do not meet the Naval Special Warfare standard.  If I elect to pay for corrective surgery, how long do I have to wait before applying to be a SEAL officer?  Do I need a waiver? What are the preoperative refraction error parameters? Is this able to be waived?",
    answer: (
      <div>
        <p>
          Per NAVMED P-117 article 15-105, so long as individuals are three
          months out from PRK and LASIK refractive surgery and meet the current
          standard for corrected visual acuity, no waiver is required for PRK or
          LASIK. The current acceptable standard for accession to BUD/S is 20/70
          worse eye and 20/40 best eye.
        </p>
        <br />
        <p>
          Per NAVMED P-117 article 15-36, current refractive error [hyperopia
          (367.0), myopia (367.1), astigmatism (367.2)] or history of refractive
          error prior to any refractive surgery manifest by any refractive error
          in spherical equivalent of worse than +/-8.00 diopters is
          disqualifying.
        </p>
        <br />
        <b>Conditions that cannot be waived:</b>
        <br />
        <ol className="list-decimal list-inside mx-12">
          <li>Pre-op spherical equivalent of &gt; +/-10.00 diopters</li>
          <li>
            Pre-op cylinder of &gt; +/-6.00, or evidence of irregular
            astigmatism on topography/pentacam
          </li>
          <li>
            Pre-op spherical equivalent of &gt; +/-8.00, if the dilated retinal
            exam showed lattice degeneration, retinal tears/holes or other
            pathology that would increase the risk of retinal detachment in the
            future
          </li>
        </ol>
      </div>
    ),
  },
  {
    question: "What are the requirements for testing color blindness?",
    answer:
      "Color blindness requirements are tested via the Farnsworth Lantern (FALANT) test.",
  },
  {
    question:
      "There are a number of physical fitness programs that advertise themselves as recommended Basic Underwater Demolition/SEAL training preparation programs.  What is the official recommended training program to prepare for BUD/S?",
    answer: (
      <p>
        The only officially recognized BUD/S preparation program is the{" "}
        <a
          href="https://www.sealswcc.com/training/navy-seal-swcc-physical-training-guide.html"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          NSW Physical Training Guide
        </a>
        .
      </p>
    ),
  },
  {
    question:
      "What is the service obligation for inter-service transfer selectees?",
    answer:
      "A selectee will be required to serve at least four years of active service in the regular component and retain his commission for a minimum of eight years at the option of Navy Personnel Command.",
  },
  {
    question:
      "Does service in the Peace Corps make me ineligible for a commission in the Navy and follow-on orders to Basic Underwater Demolition/SEAL training?",
    answer:
      "No, there is currently not a restriction on former members of the Peace Corps being SEAL officers.",
  },
  {
    question: "If I DOR during SOAS can I still comeback for a second attempt?",
    answer:
      "Yes.  If you have previously been dropped from BUD/S while attending as an Officer, you will not be reconsidered for SOAS.",
  },
];

export default function Example() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-16 sm:py-16 lg:px-8 lg:py-16">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
            Frequently asked questions
          </h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <PlusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">
                        {faq.answer}
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
