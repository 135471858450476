import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./elements/table";

import { Input } from "./elements/input";
import { Button } from "./elements/button";
import { createForeignLanguage } from "../graphql/mutations";
import { generateClient } from "aws-amplify/api";
import { useNavigate } from "react-router-dom";

interface InputState {
  usersID: string;
  foreignLanguage: string;
  proficientLvl: string;
  countryExperience: string;
  experienceyears: string;
}

interface LanguageInfoProps {
  userId: string;
}

const LanguageInfo: React.FC<LanguageInfoProps> = ({ userId }) => {
  const client = generateClient();
  const navigate = useNavigate();

  // Get the user ID from the current user
  const [userID, setUserID] = useState<string>("");
  const [languages, setLanguages] = useState<InputState[]>([
    // Default state for the first row
    {
      usersID: "",
      foreignLanguage: "",
      proficientLvl: "",
      countryExperience: "",
      experienceyears: "",
    },
  ]);

  const createNewForeignLanguage = async (index: number, usersID: string) => {
    try {
      const newForeignLanguage = await client.graphql({
        query: createForeignLanguage,
        variables: {
          input: {
            usersID: usersID,
            foreignLanguage: languages[index].foreignLanguage,
            proficientLvl: languages[index].proficientLvl,
            countryExperience: languages[index].countryExperience,
            experienceyears: languages[index].experienceyears,
          },
        },
      });

      console.log("New foreign language created:", newForeignLanguage);
    } catch (error) {
      console.error("Error creating new foreign language:", error);
    }
  };

  const [rowCount, setRowCount] = useState(1);

  const handleAddRow = (event: React.MouseEvent) => {
    event.preventDefault();
    setRowCount((prevRowCount) => prevRowCount + 1);
    setLanguages((prevLanguages) => [
      ...prevLanguages,
      {
        usersID: "",
        foreignLanguage: "",
        proficientLvl: "",
        countryExperience: "",
        experienceyears: "",
      },
    ]);
  };

  const handleRemoveRow = (event: React.MouseEvent) => {
    event.preventDefault();
    if (rowCount > 1) {
      setRowCount((prevRowCount) => prevRowCount - 1);
      setLanguages((prevLanguages) => prevLanguages.slice(0, -1));
    }
  };

  const handleLanguageChange = (index: number, value: string) => {
    setLanguages((prevLanguages) =>
      prevLanguages.map((language, i) =>
        i === index ? { ...language, foreignLanguage: value } : language
      )
    );
  };

  const handleProficiencyChange = (index: number, value: string) => {
    setLanguages((prevLanguages) =>
      prevLanguages.map((language, i) =>
        i === index ? { ...language, proficientLvl: value } : language
      )
    );
  };

  const handleCountryExperienceChange = (index: number, value: string) => {
    setLanguages((prevLanguages) =>
      prevLanguages.map((language, i) =>
        i === index ? { ...language, countryExperience: value } : language
      )
    );
  };

  const handleExperienceYearsChange = (index: number, value: string) => {
    setLanguages((prevLanguages) =>
      prevLanguages.map((language, i) =>
        i === index ? { ...language, experienceyears: value } : language
      )
    );
  };

  const handleSaveData = async (event: React.MouseEvent) => {
    event.preventDefault();

    // Use Promise.all to wait for all asynchronous calls to complete
    await Promise.all(
      languages.map((rowData, index) => createNewForeignLanguage(index, userID))
    );

    // Additional logic after all calls have completed, if needed
  };

  useEffect(() => {
    setUserID(userId);
  }, [userId]);

  return (
    <fieldset className="pb-200">
      <h2 className="text-base font-semibold leading-7 text-gray-900">
        Language Information
      </h2>
      <p className="mt-1 text-sm leading-6 text-gray-600">
        Input languages that you are proficient in.
      </p>
      <div className="flex space-x-2">
        <Button
          onClick={handleAddRow}
          className="px-2 py-1 bg-green-700 text-white rounded-md hover:bg-green-800"
        >
          Add Language
        </Button>
        <button
          onClick={handleRemoveRow}
          className="px-2 py-1 bg-red-500 text-white rounded-md hover:bg-red-600"
        >
          Remove Language
        </button>
      </div>
      <Table>
        <TableCaption>
          A list of non-English languages you are fluent in.
        </TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[240px]">Language</TableHead>
            <TableHead>Proficiency</TableHead>
            <TableHead>Overseas Experience (Country)</TableHead>
            <TableHead>Years of Proficiency</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {[...Array(rowCount)].map((_, index) => (
            <TableRow key={index}>
              <TableCell className="font-medium">
                <Input
                  id={`language${index}`}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleLanguageChange(index, e.target.value)
                  }
                />
              </TableCell>
              <TableCell>
                <Input
                  id={`proficiency${index}`}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleProficiencyChange(index, e.target.value)
                  }
                />
              </TableCell>
              <TableCell>
                <Input
                  id={`overseas-experience${index}`}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleCountryExperienceChange(index, e.target.value)
                  }
                />
              </TableCell>
              <TableCell>
                <Input
                  id={`years-proficiency${index}`}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleExperienceYearsChange(index, e.target.value)
                  }
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className="flex justify-end">
        <button
          onClick={handleSaveData}
          className="px-2 py-1 bg-green-500 text-white rounded-md hover:bg-green-600"
        >
          Save language info
        </button>
      </div>
    </fieldset>
  );
};

export default LanguageInfo;
