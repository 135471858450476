import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./elements/table";
import { Input } from "./elements/input";
import { ComboBoxTwo } from "./elements/comboBoxTwo";
import { Button } from "./elements/button";
import { generateClient } from "aws-amplify/api";
import { branches, dischargeTypes } from "../entities/serviceOptions";
import { createServiceHistory } from "../graphql/mutations";
import { set } from "date-fns";
interface ServiceHistoryProps {
  userId: string;
}

interface InputState {
  usersID: string;
  branch: string;
  yearsOfService: number;
  paygrade: string;
  dischargeType: string;
}

const ServiceHistoryInfo: React.FC<ServiceHistoryProps> = ({ userId }) => {
  const client = generateClient();

  const [userID, setUserID] = useState<string>("");
  const [serviceHistory, setServiceHistory] = useState<InputState[]>([
    // Default state for the first row
    {
      usersID: "",
      branch: "",
      yearsOfService: 0,
      paygrade: "",
      dischargeType: "",
    },
  ]);
  const [rowCount, setRowCount] = useState(1);
  const initServiceHistory = async (index: number, usersID: string) => {
    try {
      const newServiceHistory = await client.graphql({
        query: createServiceHistory,
        variables: {
          input: {
            usersID: usersID,
            BranchComponent: serviceHistory[index].branch,
            yearsOfService: serviceHistory[index].yearsOfService,
            paygrade: serviceHistory[index].paygrade,
            dischargeType: serviceHistory[index].dischargeType,
          },
        },
      });
      console.log("New service history:", newServiceHistory);
      // Rest of your code to handle the result, if needed
    } catch (error) {
      // Handle errors
      console.error("Error creating new service history:", error);
    }
  };
 
  const [selectedServiceHistory, setSelectedServiceHistory] = useState<
    string[]
  >(Array(rowCount).fill(""));

  const handleAddRow = (event: React.MouseEvent) => {
    event.preventDefault();
    setRowCount((prevRowCount) => prevRowCount + 1);
   
    setServiceHistory((prevServiceHistory) => [
      ...prevServiceHistory,
      {
        usersID: userID,
        branch: "",
        yearsOfService: 0,
        paygrade: "",
        dischargeType: "",
      },
    ]);
  };
  const handleYearsOfServiceChange = (index: number, value: string) => {
    setServiceHistory((prevServiceHistory) => {
      const newServiceHistory = [...prevServiceHistory];

      newServiceHistory[index].yearsOfService = parseInt(value);
      return newServiceHistory;
    });
  };
  const handleRemoveRow = () => {

    if (rowCount > 1) {
    setRowCount(rowCount - 1);
   

 // Remove the last entry from selectedActivity
 const newBranchType = { ...selectedBranch };
 delete newBranchType[`Branches${rowCount - 1}`];
 setSelectedBranch(newBranchType);
 // Remove the last entry from selectedActivity
 const newDischargeType = { ...selectedDischargeType };
 delete newDischargeType[`DischargeType${rowCount - 1}`];
 setSelectedDischargeType(newDischargeType);

 // Remove the last row from xC
 setServiceHistory((prevServiceHistoric) => prevServiceHistoric.slice(0, -1));
    }
  };
  const [selectedBranch, setSelectedBranch] = useState<{
    [id: string]: string;
  }>({});
  const [selectedDischargeType, setSelectedDischargeType] = useState<{
    [id: string]: string;
  }>({});

  

  const handleDischargeTypeChange = (index: number, id: string, value: string) => {
    setSelectedDischargeType((prevDischargeType) => ({
      ...prevDischargeType,
      [id]: value,
    })); 

   setServiceHistory((prevServiceHistory) =>
      prevServiceHistory.map((serviceHistoryInput, i) =>
        i === index ? { ...serviceHistoryInput, dischargeType: value } : serviceHistoryInput
      )
    );
  };

  const handleBranchChange = (index: number, id: string, value: string) => {
    setSelectedBranch((prevBranch) => ({
      ...prevBranch,
      [id]: value,
    }));

    setServiceHistory((prevServiceHistory) =>
      prevServiceHistory.map((serviceHistoryInput, i) =>
        i === index ? { ...serviceHistoryInput, branch: value } : serviceHistoryInput
      )
    );
  };

  const handlePaygradeChange = (index: number, value: string) => {
    setServiceHistory((prevServiceHistory) => {
      const newServiceHistory = [...prevServiceHistory];

      newServiceHistory[index].paygrade = value;
      return newServiceHistory;
    });
  };
  const handleSaveData = async (event: React.MouseEvent) => {
    event.preventDefault();

    await Promise.all(
      serviceHistory.map((_, index) => initServiceHistory(index, userID))
    );
  };

  useEffect(() => {
    setUserID(userId);
  }, [userId]);
  return (
    <div className="pb-200">
      <h2 className="text-base font-semibold leading-7 text-gray-900">
        Service History
      </h2>
      <p className="mt-1 text-sm leading-6 text-gray-600">
        Input data from your prior service contracts.
      </p>
      <div className="flex space-x-2">
        <Button
          onClick={handleAddRow}
          className="px-2 py-1 bg-green-700 text-white rounded-md hover:bg-green-800"
        >
          Add Service
        </Button>
        <button
          onClick={handleRemoveRow}
          className="px-2 py-1 bg-red-500 text-white rounded-md hover:bg-red-600"
        >
          Remove Service
        </button>
      </div>
      <Table>
        <TableCaption>A list of your prior service experience</TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[240px]">Branch/Component</TableHead>
            <TableHead>Years of Service</TableHead>

            <TableHead>Paygrade</TableHead>
            <TableHead>Discharge Type</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {[...Array(rowCount)].map((_, index) => (
            <TableRow key={index}>
              <TableCell>
              <ComboBoxTwo
                  index={index} // Pass the index to the Combobox
                  id={`branch-${index}`}
                  options={branches}
                  value={selectedBranch[`branch-${index}`] || ""}
                  onChange={(id: string, index: number, value: string) =>
                    handleBranchChange(index, id, value)
                  } // Adjust the onChange to pass index
                  placeholder="Branch/Component"
                />
              </TableCell>
              <TableCell>
                <Input
                  id={`years-of-service${index}`}
                  type="number"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleYearsOfServiceChange(index, e.target.value)
                  }
                />
              </TableCell>
              <TableCell>
                <Input
                  id={`paygrade${index}`}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handlePaygradeChange(index, e.target.value)
                  }
                />
              </TableCell>
              <TableCell>
                <ComboBoxTwo
                  index={index}
                  id={`dischargeType-${index}`}
                  options={dischargeTypes}
                  value={selectedDischargeType[`dischargeType-${index}`] || ""}
                  onChange={(id: string, index: number, value: string) =>
                    handleDischargeTypeChange(index, id, value)
                  }
                  placeholder="Select Discharge Type"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className="flex justify-end">
        <button
          onClick={handleSaveData} //TODO: Add mutation
          className="px-2 py-1 bg-green-500 text-white rounded-md hover:bg-green-600"
        >
          Save service history
        </button>
      </div>
    </div>
  );
};

export default ServiceHistoryInfo;
