import React, { useState,useEffect } from 'react';
import { signUp, confirmSignUp, signIn } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { generateClient } from "aws-amplify/api";


import { getCurrentUser } from 'aws-amplify/auth';

import {MainContainer,
  BoxL,
  BoxR,
  OperatorImage,
  Title,
  TimeLineTitle

} from "../styling/LoginElements";

import "../styling/LoginStyles.css";

import myImage from '../assets/operator2.jpg';
const Login = () => {
  const client = generateClient()
  const navigate = useNavigate();  // Use useNavigate
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [verificationStage, setVerificationStage] = useState<string>('create');
  const [error, setError] = useState<string>('');
  
  async function currentAuthenticatedUser() {
    try {
      const { username, userId, signInDetails } = await getCurrentUser();
      console.log(`The username: ${username}`);
      console.log(`The userId: ${userId}`);
      console.log(`The signInDetails: ${signInDetails}`);
    } catch (err) {
      console.log(err);
      navigate("/login")
    }
  }

  const handleVerification = async (): Promise<void> => {
    try {
      await confirmSignUp({
        username: email,
        confirmationCode: verificationCode,
      });
      setVerificationStage('success'); // Verification successful
    } catch (error) {
      console.error('Error verifying email:', error);
      setError('Verification code is incorrect.');
    }
  };

  const handleSignIn = async (): Promise<void> => {
    setError(''); // Clear any previous errors

    try {
      await signIn({ username: email, password });
      // Handle successful sign-in (you can add a redirect here)
      navigate('/Apply');
      console.log('Sign-in successful!');
    } catch (error) {
      console.error('Error signing in:', error);
      setError('Incorrect email or password.');
    }
  };

  const handleForgotPassword = async (): Promise<void> => {
    setError(''); // Clear any previous errors

    try {
      // Implement your own logic for forgot password if needed
      console.log('Forgot password initiated.');
    } catch (error) {
      console.error('Error initiating forgot password:', error);
      setError('Error initiating forgot password.');
    }
  };

  const checkAuth = async () => {
    try {
      await currentAuthenticatedUser();
        
      
    } catch (error) {
      if (error == null) {
        navigate('/Apply');
      } else {
        console.error('User Status:', error);
      }
    }
  };

  useEffect(() => {
   

    checkAuth();
  }, [navigate]);
 
  return (
    <div >
    <MainContainer>
   
      <BoxL>
      <Title>CONTINUE APPLICATION</Title>
      <OperatorImage src={myImage} alt="arrow" />
      
      </BoxL>
      <BoxR>
      <TimeLineTitle className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">WELCOME BACK</TimeLineTitle>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="bg-white py-8 px-4 ">
        <div>
          {verificationStage === 'create' && (
            <>
              <div className="mt-1">
                <label
                  htmlFor="email-address"
                  className="block text-sm  text-gray-700 font-medium custom-font"
                >
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm mt-1 custom-font2 "
                  placeholder="Email address"
                  style={{ fontWeight: 'lighter' }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="mt-6">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700 custom-font"
                  
                >
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm custom-font2"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

             

            </>
          )}

          {verificationStage === 'verify' && (
            <>
              <label className="custom-font2">Verification Code:</label>
              <input
                type="text"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm mt-1 custom-font2"
                placeholder="Verification Code"
              />
            </>
          )}

          {error && <p style={{ color: 'red' }}>{error}</p>}
          <div className="mt-6">
            {verificationStage === 'create' ? (
              <>
                {/* Use handleSignUp for the initial sign-up */}
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 custom-font"
                  onClick={handleSignIn}
>
  LOGIN
</button>

              </>
            ) : verificationStage === 'verify' ? (
              <>
                {/* Use handleVerification for verification */}
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 custom-font"
                  onClick={handleVerification}
                >
                  Verify
                </button>
              </>
            ) : (
              <>
                {/* Render a message when verification is successful */}
                Thank You for Signing Up
              </>
            )}
          </div>
        </div>
      </div>
    </div>
      </BoxR>
   
    </MainContainer>
<MainContainer>
<BoxL> 

</BoxL>
<BoxR>    </BoxR>
   
   </MainContainer>
    </div>
  );
};

export default Login;
