import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { NavBar } from "./components";
import { About, Apply, FAQ, LandingPage, Timeline, Login } from "./pages";
import React, { useEffect } from 'react';
import { signOut } from 'aws-amplify/auth';
const App = () => {
  
  async function handleSignOut() {
    try {
      await signOut({ global: true });
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }
  const handleBeforeUnload = async () => {
    try {
      await handleSignOut();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  return (
    <Router basename="">
      <div>
        <NavBar />
        <div className="max-w-7xl mx-auto">
          <Routes>
            <Route path="/About" element={<About />} />
            <Route path="/Apply" element={<Apply />} />
            <Route path="/FAQ" element={<FAQ />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/Timeline" element={<Timeline />} />
            <Route path="/" element={<LandingPage />} /> 
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
