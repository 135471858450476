/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createDocuments = /* GraphQL */ `mutation CreateDocuments(
  $input: CreateDocumentsInput!
  $condition: ModelDocumentsConditionInput
) {
  createDocuments(input: $input, condition: $condition) {
    id
    fileName
    s3Key
    usersID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDocumentsMutationVariables,
  APITypes.CreateDocumentsMutation
>;
export const updateDocuments = /* GraphQL */ `mutation UpdateDocuments(
  $input: UpdateDocumentsInput!
  $condition: ModelDocumentsConditionInput
) {
  updateDocuments(input: $input, condition: $condition) {
    id
    fileName
    s3Key
    usersID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDocumentsMutationVariables,
  APITypes.UpdateDocumentsMutation
>;
export const deleteDocuments = /* GraphQL */ `mutation DeleteDocuments(
  $input: DeleteDocumentsInput!
  $condition: ModelDocumentsConditionInput
) {
  deleteDocuments(input: $input, condition: $condition) {
    id
    fileName
    s3Key
    usersID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDocumentsMutationVariables,
  APITypes.DeleteDocumentsMutation
>;
export const createServiceHistory = /* GraphQL */ `mutation CreateServiceHistory(
  $input: CreateServiceHistoryInput!
  $condition: ModelServiceHistoryConditionInput
) {
  createServiceHistory(input: $input, condition: $condition) {
    id
    BranchComponent
    yearsOfService
    paygrade
    dischargeType
    usersID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateServiceHistoryMutationVariables,
  APITypes.CreateServiceHistoryMutation
>;
export const updateServiceHistory = /* GraphQL */ `mutation UpdateServiceHistory(
  $input: UpdateServiceHistoryInput!
  $condition: ModelServiceHistoryConditionInput
) {
  updateServiceHistory(input: $input, condition: $condition) {
    id
    BranchComponent
    yearsOfService
    paygrade
    dischargeType
    usersID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateServiceHistoryMutationVariables,
  APITypes.UpdateServiceHistoryMutation
>;
export const deleteServiceHistory = /* GraphQL */ `mutation DeleteServiceHistory(
  $input: DeleteServiceHistoryInput!
  $condition: ModelServiceHistoryConditionInput
) {
  deleteServiceHistory(input: $input, condition: $condition) {
    id
    BranchComponent
    yearsOfService
    paygrade
    dischargeType
    usersID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteServiceHistoryMutationVariables,
  APITypes.DeleteServiceHistoryMutation
>;
export const createExtraCurricula = /* GraphQL */ `mutation CreateExtraCurricula(
  $input: CreateExtraCurriculaInput!
  $condition: ModelExtraCurriculaConditionInput
) {
  createExtraCurricula(input: $input, condition: $condition) {
    id
    usersID
    activityType
    leadershipLvl
    levelParticipated
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateExtraCurriculaMutationVariables,
  APITypes.CreateExtraCurriculaMutation
>;
export const updateExtraCurricula = /* GraphQL */ `mutation UpdateExtraCurricula(
  $input: UpdateExtraCurriculaInput!
  $condition: ModelExtraCurriculaConditionInput
) {
  updateExtraCurricula(input: $input, condition: $condition) {
    id
    usersID
    activityType
    leadershipLvl
    levelParticipated
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateExtraCurriculaMutationVariables,
  APITypes.UpdateExtraCurriculaMutation
>;
export const deleteExtraCurricula = /* GraphQL */ `mutation DeleteExtraCurricula(
  $input: DeleteExtraCurriculaInput!
  $condition: ModelExtraCurriculaConditionInput
) {
  deleteExtraCurricula(input: $input, condition: $condition) {
    id
    usersID
    activityType
    leadershipLvl
    levelParticipated
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteExtraCurriculaMutationVariables,
  APITypes.DeleteExtraCurriculaMutation
>;
export const createEmploymentHistory = /* GraphQL */ `mutation CreateEmploymentHistory(
  $input: CreateEmploymentHistoryInput!
  $condition: ModelEmploymentHistoryConditionInput
) {
  createEmploymentHistory(input: $input, condition: $condition) {
    id
    employmentHistory
    usersID
    companyName
    titleOrPosition
    startDate
    endDate
    reasonForLeaving
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEmploymentHistoryMutationVariables,
  APITypes.CreateEmploymentHistoryMutation
>;
export const updateEmploymentHistory = /* GraphQL */ `mutation UpdateEmploymentHistory(
  $input: UpdateEmploymentHistoryInput!
  $condition: ModelEmploymentHistoryConditionInput
) {
  updateEmploymentHistory(input: $input, condition: $condition) {
    id
    employmentHistory
    usersID
    companyName
    titleOrPosition
    startDate
    endDate
    reasonForLeaving
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEmploymentHistoryMutationVariables,
  APITypes.UpdateEmploymentHistoryMutation
>;
export const deleteEmploymentHistory = /* GraphQL */ `mutation DeleteEmploymentHistory(
  $input: DeleteEmploymentHistoryInput!
  $condition: ModelEmploymentHistoryConditionInput
) {
  deleteEmploymentHistory(input: $input, condition: $condition) {
    id
    employmentHistory
    usersID
    companyName
    titleOrPosition
    startDate
    endDate
    reasonForLeaving
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEmploymentHistoryMutationVariables,
  APITypes.DeleteEmploymentHistoryMutation
>;
export const createForeignLanguage = /* GraphQL */ `mutation CreateForeignLanguage(
  $input: CreateForeignLanguageInput!
  $condition: ModelForeignLanguageConditionInput
) {
  createForeignLanguage(input: $input, condition: $condition) {
    id
    usersID
    foreignLanguage
    proficientLvl
    countryExperience
    experienceyears
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateForeignLanguageMutationVariables,
  APITypes.CreateForeignLanguageMutation
>;
export const updateForeignLanguage = /* GraphQL */ `mutation UpdateForeignLanguage(
  $input: UpdateForeignLanguageInput!
  $condition: ModelForeignLanguageConditionInput
) {
  updateForeignLanguage(input: $input, condition: $condition) {
    id
    usersID
    foreignLanguage
    proficientLvl
    countryExperience
    experienceyears
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateForeignLanguageMutationVariables,
  APITypes.UpdateForeignLanguageMutation
>;
export const deleteForeignLanguage = /* GraphQL */ `mutation DeleteForeignLanguage(
  $input: DeleteForeignLanguageInput!
  $condition: ModelForeignLanguageConditionInput
) {
  deleteForeignLanguage(input: $input, condition: $condition) {
    id
    usersID
    foreignLanguage
    proficientLvl
    countryExperience
    experienceyears
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteForeignLanguageMutationVariables,
  APITypes.DeleteForeignLanguageMutation
>;
export const createEducation = /* GraphQL */ `mutation CreateEducation(
  $input: CreateEducationInput!
  $condition: ModelEducationConditionInput
) {
  createEducation(input: $input, condition: $condition) {
    id
    collegeName
    collegeCity
    collegeState
    degreeRecieved
    majorDegree
    gpaDegree
    gpaScore
    usersID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEducationMutationVariables,
  APITypes.CreateEducationMutation
>;
export const updateEducation = /* GraphQL */ `mutation UpdateEducation(
  $input: UpdateEducationInput!
  $condition: ModelEducationConditionInput
) {
  updateEducation(input: $input, condition: $condition) {
    id
    collegeName
    collegeCity
    collegeState
    degreeRecieved
    majorDegree
    gpaDegree
    gpaScore
    usersID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEducationMutationVariables,
  APITypes.UpdateEducationMutation
>;
export const deleteEducation = /* GraphQL */ `mutation DeleteEducation(
  $input: DeleteEducationInput!
  $condition: ModelEducationConditionInput
) {
  deleteEducation(input: $input, condition: $condition) {
    id
    collegeName
    collegeCity
    collegeState
    degreeRecieved
    majorDegree
    gpaDegree
    gpaScore
    usersID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEducationMutationVariables,
  APITypes.DeleteEducationMutation
>;
export const createAccessionSource = /* GraphQL */ `mutation CreateAccessionSource(
  $input: CreateAccessionSourceInput!
  $condition: ModelAccessionSourceConditionInput
) {
  createAccessionSource(input: $input, condition: $condition) {
    id
    yearCommissioned
    accessionSource
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAccessionSourceMutationVariables,
  APITypes.CreateAccessionSourceMutation
>;
export const updateAccessionSource = /* GraphQL */ `mutation UpdateAccessionSource(
  $input: UpdateAccessionSourceInput!
  $condition: ModelAccessionSourceConditionInput
) {
  updateAccessionSource(input: $input, condition: $condition) {
    id
    yearCommissioned
    accessionSource
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAccessionSourceMutationVariables,
  APITypes.UpdateAccessionSourceMutation
>;
export const deleteAccessionSource = /* GraphQL */ `mutation DeleteAccessionSource(
  $input: DeleteAccessionSourceInput!
  $condition: ModelAccessionSourceConditionInput
) {
  deleteAccessionSource(input: $input, condition: $condition) {
    id
    yearCommissioned
    accessionSource
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAccessionSourceMutationVariables,
  APITypes.DeleteAccessionSourceMutation
>;
export const createPhysicalScreeningtest = /* GraphQL */ `mutation CreatePhysicalScreeningtest(
  $input: CreatePhysicalScreeningtestInput!
  $condition: ModelPhysicalScreeningtestConditionInput
) {
  createPhysicalScreeningtest(input: $input, condition: $condition) {
    id
    testDate
    TestAdministrator
    SwimTime
    RunTime
    pushUps
    sitUps
    pullUps
    compositeScore
    usersID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePhysicalScreeningtestMutationVariables,
  APITypes.CreatePhysicalScreeningtestMutation
>;
export const updatePhysicalScreeningtest = /* GraphQL */ `mutation UpdatePhysicalScreeningtest(
  $input: UpdatePhysicalScreeningtestInput!
  $condition: ModelPhysicalScreeningtestConditionInput
) {
  updatePhysicalScreeningtest(input: $input, condition: $condition) {
    id
    testDate
    TestAdministrator
    SwimTime
    RunTime
    pushUps
    sitUps
    pullUps
    compositeScore
    usersID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePhysicalScreeningtestMutationVariables,
  APITypes.UpdatePhysicalScreeningtestMutation
>;
export const deletePhysicalScreeningtest = /* GraphQL */ `mutation DeletePhysicalScreeningtest(
  $input: DeletePhysicalScreeningtestInput!
  $condition: ModelPhysicalScreeningtestConditionInput
) {
  deletePhysicalScreeningtest(input: $input, condition: $condition) {
    id
    testDate
    TestAdministrator
    SwimTime
    RunTime
    pushUps
    sitUps
    pullUps
    compositeScore
    usersID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePhysicalScreeningtestMutationVariables,
  APITypes.DeletePhysicalScreeningtestMutation
>;
export const createUsers = /* GraphQL */ `mutation CreateUsers(
  $input: CreateUsersInput!
  $condition: ModelUsersConditionInput
) {
  createUsers(input: $input, condition: $condition) {
    id
    emailAddress
    lastName
    firstName
    middleName
    phoneNumber
    EmploymentHistories {
      items {
        id
        fileName
        s3Key
        usersID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    ExtraCurricula {
      items {
        id
        fileName
        s3Key
        usersID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    ForeignLanguages {
      items {
        id
        fileName
        s3Key
        usersID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    AccessionSource {
      id
      yearCommissioned
      accessionSource
      createdAt
      updatedAt
      owner
      __typename
    }
    Education {
      items {
        id
        fileName
        s3Key
        usersID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    PhysicalScreeningtests {
      items {
        id
        fileName
        s3Key
        usersID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    ServiceHistories {
      items {
        id
        fileName
        s3Key
        usersID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    DOB
    measureHeight
    measureWeight
    Documents {
      items {
        id
        fileName
        s3Key
        usersID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    usersAccessionSourceId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUsersMutationVariables,
  APITypes.CreateUsersMutation
>;
export const updateUsers = /* GraphQL */ `mutation UpdateUsers(
  $input: UpdateUsersInput!
  $condition: ModelUsersConditionInput
) {
  updateUsers(input: $input, condition: $condition) {
    id
    emailAddress
    lastName
    firstName
    middleName
    phoneNumber
    EmploymentHistories {
      items {
        id
        fileName
        s3Key
        usersID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    ExtraCurricula {
      items {
        id
        fileName
        s3Key
        usersID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    ForeignLanguages {
      items {
        id
        fileName
        s3Key
        usersID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    AccessionSource {
      id
      yearCommissioned
      accessionSource
      createdAt
      updatedAt
      owner
      __typename
    }
    Education {
      items {
        id
        fileName
        s3Key
        usersID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    PhysicalScreeningtests {
      items {
        id
        fileName
        s3Key
        usersID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    ServiceHistories {
      items {
        id
        fileName
        s3Key
        usersID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    DOB
    measureHeight
    measureWeight
    Documents {
      items {
        id
        fileName
        s3Key
        usersID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    usersAccessionSourceId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUsersMutationVariables,
  APITypes.UpdateUsersMutation
>;
export const deleteUsers = /* GraphQL */ `mutation DeleteUsers(
  $input: DeleteUsersInput!
  $condition: ModelUsersConditionInput
) {
  deleteUsers(input: $input, condition: $condition) {
    id
    emailAddress
    lastName
    firstName
    middleName
    phoneNumber
    EmploymentHistories {
      items {
        id
        fileName
        s3Key
        usersID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    ExtraCurricula {
      items {
        id
        fileName
        s3Key
        usersID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    ForeignLanguages {
      items {
        id
        fileName
        s3Key
        usersID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    AccessionSource {
      id
      yearCommissioned
      accessionSource
      createdAt
      updatedAt
      owner
      __typename
    }
    Education {
      items {
        id
        fileName
        s3Key
        usersID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    PhysicalScreeningtests {
      items {
        id
        fileName
        s3Key
        usersID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    ServiceHistories {
      items {
        id
        fileName
        s3Key
        usersID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    DOB
    measureHeight
    measureWeight
    Documents {
      items {
        id
        fileName
        s3Key
        usersID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    usersAccessionSourceId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUsersMutationVariables,
  APITypes.DeleteUsersMutation
>;
