

import { Input } from "./input";
import { Label } from "./label";

export function InputFile() {
  return (
    <div className="grid w-full max-w-sm items-center gap-1.5">
      <Input id="inputfile" type="file" />
    </div>
  );
}


// elements/InputFile.tsx
/*
import React, { ChangeEvent } from "react";
import { Input } from "./input";

interface InputFileProps {
  onChange: (file: File, description: string) => void;
  onDescriptionChange: (description: string) => void;
  description: string;
}

const InputFile: React.FC<InputFileProps> = ({ onChange, onDescriptionChange, description }) => {
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      onChange(file, description);
    }
  };

  return (
    <div className="grid w-full max-w-sm items-center gap-1.5">
      <Input id="inputfile" type="file" onChange={handleFileChange} />
      <input
        type="text"
        placeholder="File description"
        value={description}
        onChange={(e) => onDescriptionChange(e.target.value)}
        className="border p-1 mt-2"
      />
    </div>
  );
};

export default InputFile;

*/
