// RadioButtons.tsx
import React from "react";

interface RadioButtonsProps {
  selectedOption: string;
  handleOptionChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  options: { label: string; value: string }[];
}

const RadioButtons: React.FC<RadioButtonsProps> = ({
  selectedOption,
  handleOptionChange,
  options,
}) => {
  return (
    <fieldset>
      <div className="mt-6 space-y-6">
        {options.map((option) => (
          <div key={option.value} className="flex items-center gap-x-3">
            <input
              id={`push-${option.value}`}
              name="push-notifications"
              type="radio"
              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              checked={selectedOption === option.value}
              onChange={handleOptionChange}
              value={option.value}
            />
            <label
              htmlFor={`push-${option.value}`}
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </fieldset>
  );
};

export default RadioButtons;
