import React, { ChangeEvent } from "react";

interface TimeInputProps {
  minutes: string;
  seconds: string;
  onChange: (minutes: string, seconds: string) => void;
}

const TimeInput: React.FC<TimeInputProps> = ({ minutes, seconds, onChange }) => {
  const handleMinutesChange = (e: ChangeEvent<HTMLSelectElement>) => {
    onChange(e.target.value, seconds);
  };

  const handleSecondsChange = (e: ChangeEvent<HTMLSelectElement>) => {
    onChange(minutes, e.target.value);
  };

  const generateOptions = (start: number, end: number) => {
    const options = [];
    for (let i = start; i <= end; i++) {
      const formattedValue = i.toString().padStart(2, "0"); // Add leading zero if necessary
      options.push(<option key={formattedValue} value={formattedValue}>{formattedValue}</option>);
    }
    return options;
  };

  return (
    <div className="flex space-x-2 items-center">
      <select
        value={minutes}
        onChange={handleMinutesChange}
        className="w-16 px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-indigo-300 appearance-none"
      >
        {generateOptions(0, 14)} {/* Updated the range for minutes */}
      </select>
      <span className="text-xl font-bold">:</span>
      <select
        value={seconds}
        onChange={handleSecondsChange}
        className="w-16 px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-indigo-300 appearance-none"
      >
        {generateOptions(0, 60)}
      </select>
    </div>
  );
};

export default TimeInput;
