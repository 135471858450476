export const branches = [
  { label: "Army", value: "army" },
  { label: "Air Force", value: "air-force" },
  { label: "Navy", value: "navy" },
  { label: "Marines", value: "marines" },
  { label: "Coast Guard", value: "coast-guard" },
  { label: "Merchant Marine", value: "merchant-marine" },
  { label: "Space Force", value: "space-force" },
  { label: "National Guard", value: "national-guard" },
  { label: "Other", value: "other" },
];
export const dischargeTypes = [
  { label: "Honorable", value: "honorable" },
  { label: "General", value: "general" },
  { label: "Other Than Honorable", value: "other-than-honorable" },
];
