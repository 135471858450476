import React from "react";

const Timeline = () => {
  return (
    <div className="mx-auto py-24 max-w-7 xl">
      <ol className="relative border-s border-gray-200 dark:border-gray-700">
        <li className="mb-10 ms-4">
          <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
          <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
            1 - OCT - 23
          </time>
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
            Application window opens for SEAL officer candidates
          </h3>
          <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
            See the Official Navy HR website for further information
          </p>
          <a
            href="https://www.mynavyhr.navy.mil/Career-Management/Community-Management/Officer/Active-OCM/Unrestricted-Line/Special-Warfare-OCM/SEAL-Officer-Selection/"
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-200 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-700"
          >
            Learn more{" "}
            <svg
              className="w-3 h-3 ms-2 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </a>
        </li>
        <li className="mb-10 ms-4">
          <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
          <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
            23 - FEB - 24
          </time>
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
            Applications are due for personnel attending BUD/s
          </h3>
          <p className="text-base font-normal text-gray-500 dark:text-gray-400">
            All of the pages and components are first designed in Figma and we
            keep a parity between the two versions even as we update the
            project.
          </p>
        </li>
        <li className="ms-4">
          <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
          <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
            15 - JUN - 24
          </time>
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
            Applications are due for Active Duty SEALs attending OCS
          </h3>
        </li>
      </ol>
    </div>
  );
};

export default Timeline;
