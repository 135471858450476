import RadioButtons from "./elements/Radiobuttons";
import { InputFile } from "./elements/inputfile";
import React, { useState, useEffect, ChangeEvent } from "react";
import Dropdown from "./elements/Dropdown";
import { UserIdProps } from "../constants/props";
import { render } from "@testing-library/react";
import {createDocuments } from "../graphql/mutations";
import { generateClient } from "aws-amplify/api";
import { uploadData } from 'aws-amplify/storage';
import { updateDocuments } from 'src/graphql/mutations';

import {
  accessionSources,
  academyOptions,
  OCSOptions,
  serviceOptions,
} from "../entities/sourceOptions";
import {
  commonFiles,
  navalAcademyFiles,
  interAcademyTransferFiles,
  ocsActiveDutyFiles,
  ocsCivilianFiles,
  rotcFiles,
  lateralTransferFiles,
  interServiceTransferFiles,
} from "../entities/requiredFiles";



interface InputState {
  usersID: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

const AccessionSourceInfo: React.FC<UserIdProps> = ({ userId }) => {

  const client = generateClient();
  const [userID, setUserID] = useState<string>("");

  const [selectedAccessionSources, setSelectedAccessionSources] = useState<{
    [id: string]: string;
  }>({});

  const handleAccessionSourceChange = (id: string, value: string) => {
    setSelectedAccessionSources((prevAccessionSources) => ({
      ...prevAccessionSources,
      [id]: value,
    }));
  };
  const [selectedOption, setSelectedOption] = useState("");
  const [files, setFiles] = useState(commonFiles);
  const [radioOptions, setRadioOptions] = useState(academyOptions);
  const [renderRadioButtons, setRenderRadioButtons] = useState(false); // Declare renderRadioButtons in the outer scope

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
    console.log(event.target.value);

    switch (event.target.value) {
      case "naval-academy":
        setFiles(commonFiles.concat(navalAcademyFiles));
        break;
      case "west-point":
        setFiles(commonFiles.concat(interAcademyTransferFiles));
        break;
      case "air-force-Academy":
        setFiles(commonFiles.concat(interAcademyTransferFiles));
        break;
      case "merchant-marine-academy":
        setFiles(commonFiles.concat(interAcademyTransferFiles));
        break;
      case "coast-guard-academy":
        setFiles(commonFiles.concat(interAcademyTransferFiles));
        break;
      case "active-duty":
        setFiles(commonFiles.concat(ocsActiveDutyFiles));
        break;
      case "civilian":
        setFiles(commonFiles.concat(ocsCivilianFiles));
        break;
      case "active-duty":
        setFiles(commonFiles.concat(ocsActiveDutyFiles));
        break;
      case "active-duty-seal":
        setFiles(commonFiles.concat(ocsActiveDutyFiles));
        break;
      case "rotc":
        setFiles(commonFiles.concat(rotcFiles));
        break;
      case "lateral-transfer":
        setFiles(commonFiles.concat(lateralTransferFiles));
        break;
      case "air-force":
        setFiles(commonFiles.concat(interServiceTransferFiles));
        break;
      case "army":
        setFiles(commonFiles.concat(interServiceTransferFiles));
        break;
      case "coast-guard":
        setFiles(commonFiles.concat(interServiceTransferFiles));
        break;
      case "merchant-marine":
        setFiles(commonFiles.concat(interServiceTransferFiles));
        break;
    }
  };

  const handleDropdownChange = (value: string) => {
    setSelectedOption(value);
    setFiles(commonFiles);
    switch (value) {
      case "Academy":
        setRadioOptions(academyOptions);
        setFiles(commonFiles);
        setRenderRadioButtons(true);

        break;
      case "OCS":
        setRadioOptions(OCSOptions);
        setFiles(commonFiles);
        setRenderRadioButtons(true);
        break;
      case "Inter-Service Transfer":
        setRadioOptions(serviceOptions);
        setFiles(commonFiles);
        setRenderRadioButtons(true);
        break;
      default:
        setRenderRadioButtons(false);
    }
  };
 
  const [fileName, setFileName] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setFileName(file.name);
    }
  };
    


  const createNewDocument = async ( usersID: string) => {
    try {

      
      // Create a new Documents object in DynamoDB
      const newDocument = await client.graphql({
        query: createDocuments,
        variables: {
          input: {
            fileName: fileName,
            s3Key: '', // You can generate a unique S3 key here
            usersID: userID, // Replace with the actual user ID
          },
        },
      });
   // Check if the document creation was successful and log the ID
   if (newDocument.data?.createDocuments) {
    const documentId = newDocument.data.createDocuments.id;


    if (selectedFile) {
      const s3Key = `${documentId}-${fileName}`;
     
      try {
        const result = await uploadData({
          key: s3Key,
          data: selectedFile,
          options: {
            accessLevel: 'protected'
          }
        }).result;

        const inputVariables = {
          id: documentId, // Replace with the actual document ID you want to update
          s3Key: s3Key, // Replace with the new S3 key value
        };
        
        // Execute the mutation
        try {
          const updatedDocument = await client.graphql({
            query: updateDocuments,
            variables: {
              input: inputVariables,
            },
          });
        
          console.log('Updated Document:', updatedDocument);
        } catch (error) {
          console.error('Error updating document:', error);
        }
        console.log('Succeeded: ', result);
      } catch (error) {
        console.log('Error : ', error);
      }

    }
    console.log(`Document created successfully with ID: ${documentId}`);
  } else {
    console.error('Failed to create document.');
  }
} catch (error) {
  console.error('Error creating new document:', error);
}
};


const handleSaveData = async (event: React.MouseEvent) => {
  event.preventDefault(); // Prevent the default form submission behavior

  // Use Promise.all to wait for all asynchronous calls to complete


    createNewDocument(userID)


  // Additional logic after all calls have completed, if needed
};



      const handleUpload = async () => {
          try {
            // Create a new Documents object in DynamoDB
            const newDocument = await client.graphql({
              query: createDocuments,
              variables: {
                input: {
                  fileName: fileName,
                  s3Key: '', // You can generate a unique S3 key here
                  usersID: userID, // Replace with the actual user ID
                },
              },
            });
      
            // Upload the file to S3
            if (selectedFile) {
              const s3Key = `${newDocument.data?.createDocuments.id}-${fileName}`;
             
              try {
                const result = await uploadData({
                  key: s3Key,
                  data: selectedFile,
                  options: {
                    accessLevel: 'protected'
                  }
                }).result;
                console.log('Succeeded: ', result);
              } catch (error) {
                console.log('Error : ', error);
              }
    
    
    
            }
      
            console.log('File uploaded successfully!');
          } catch (error) {
            console.error('Error:', error);
          }
        };
    
      

  useEffect(() => {
    setUserID(userId);
  });
  
  
  return (
    <fieldset>
      <legend className="text-base font-semibold leading-7 text-gray-900">
        Accession Source Information
      </legend>
      <p className="mt-1 text-sm leading-6 text-gray-600">
        Input data about where you are applying from.
      </p>
      <div className="space-y-12 space-x-2 justify-between">
        <div className="flex gap-x-12 justify-between">
          <div className="w-64">
            <Dropdown
              menuItems={accessionSources}
              handleDropdownChange={handleDropdownChange}
            />
          </div>
          <div className=" w-84">
            {renderRadioButtons && (
              <RadioButtons
                selectedOption={selectedOption}
                handleOptionChange={handleOptionChange}
                options={radioOptions}
              />
            )}
          </div>

          <div className="flex justify-end">
            <table>
              <tbody>
                {files.flatMap((file, index) =>
                  Array.from({ length: file.fileCount }, (_, countIndex) => (
                    <tr key={`${index}-${countIndex}`}>
                      <td>{file.name} </td>
                      <td className="flex justify-end ml-12">
                        <InputFile />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex justify-end">
          <button
             //TODO: Add mutation
            className="px-2 py-1 bg-green-500 text-white rounded-md hover:bg-green-600"
          >
            Save accession source info
          </button>
          <div>
          <h1>Document Upload</h1>
          <input
            type="text"
            placeholder="File Name"
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
          />
          <input type="file" onChange={handleFileChange} />
          <button onClick={handleSaveData}>Upload</button>
        </div>
        </div>

        <div className="border-b border-gray-900/10 pb-12"></div>
      </div>
    </fieldset>
  );
};
export default AccessionSourceInfo;
