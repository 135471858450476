import React, { useEffect, useState } from "react";
import ContactInfo from "../components/personalInformation";
import AthleteInfo from "../components/athleteInformation";
import CommissionInfo from "../components/scholasticHistory";
import ServiceHistoryInfo from "../components/serviceHistory";
import LanguageInfo from "../components/languageInformation";
import EmploymentHistory from "../components/EmploymentHistory";
import Extracirriculars from "../components/Extracirriculars";
import SchoolInfo from "../components/accessionSourceInfo";
import { useNavigate } from "react-router-dom";
import { getCurrentUser } from "aws-amplify/auth";

const Apply = () => {
  const navigate = useNavigate();

  const [userId, setUserId] = useState<string>("");

  async function currentAuthenticatedUser() {
    try {
      const { username, userId, signInDetails } = await getCurrentUser();
      setUserId(userId);
      console.log(`The username: ${username}`);
      console.log(`The userId: ${userId}`);
      console.log(`The signInDetails: ${signInDetails}`);
    } catch (err) {
      navigate("/login");
      console.log(err);
    }
  }

  const checkAuth = async () => {
    await currentAuthenticatedUser();
  };
  useEffect(() => {
    checkAuth();
  }, [navigate]);
  return (
    <form>
      <ContactInfo userId={userId} />
      <AthleteInfo userId={userId} />
      <SchoolInfo userId={userId} />
      <CommissionInfo userId={userId} />
      <Extracirriculars userId={userId} />
      <LanguageInfo userId={userId} />
      <ServiceHistoryInfo userId={userId} />
      <EmploymentHistory userId={userId} />
    </form>
  );
};

export default Apply;
