// Extracurriculars.tsx

import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./elements/table";
import { Input } from "./elements/input";
import { Button } from "./elements/button";
import { generateClient } from "aws-amplify/api";
import { useNavigate } from "react-router-dom";
import { ComboBoxTwo } from "./elements/comboBoxTwo";
import { createExtraCurricula } from "../graphql/mutations";
import { ExtraCurricula } from "../API";

interface InputState {
  usersID: string;
  activityType: string;
  leadershipLvl: string;
  levelParticipated: string;
}

interface ExtraCInfoProps {
  userId: string;
}

const Extracurriculars: React.FC<ExtraCInfoProps> = ({ userId }) => {
  const client = generateClient();

  // Get the user ID from the current user
  const [userID, setUserID] = useState<string>("");
  const [xC, setXC] = useState<InputState[]>([
    // Default state for the first row
    {
      usersID: "",
      activityType: "",
      leadershipLvl: "",
      levelParticipated: "",
    },
  ]);
  const participationLevels = [
    { label: "High School", value: "high school" },
    { label: "College", value: "college" },
    { label: "Extracurricular", value: "extracurricular" },
    { label: "Intramural", value: "intramural" },
    { label: "Professional", value: "professional" },
  ];

  const [selectedActivity, setSelectedActivities] = useState<{
    [id: string]: string;
  }>({});

  const createNewExtraCurricula = async (index: number, usersID: string) => {
    try {
      const newExtraCurricula = await client.graphql({
        query: createExtraCurricula,
        variables: {
          input: {
            usersID: usersID,
            activityType: xC[index].activityType,
            leadershipLvl: xC[index].leadershipLvl,
            levelParticipated: xC[index].levelParticipated,
          },
        },
      });

      console.log("New extracurricular created:", newExtraCurricula);
    } catch (error) {
      console.error("Error creating new extracurricular:", error);
    }
  };

  const handleActivityTypeChange = (index: number, value: string) => {
    setXC((prevLanguages) =>
      prevLanguages.map((ExtracurricularsInput, i) =>
        i === index
          ? { ...ExtracurricularsInput, activityType: value }
          : ExtracurricularsInput
      )
    );
  };

  const handleLeadershipChange = (index: number, value: string) => {
    setXC((prevLanguages) =>
      prevLanguages.map((ExtracurricularsInput, i) =>
        i === index
          ? { ...ExtracurricularsInput, leadershipLvl: value }
          : ExtracurricularsInput
      )
    );
  };

  const handleLevelParticipatedChange = (
    index: number,
    id: string,
    value: string
  ) => {
    setSelectedActivities((prevActivities) => ({
      ...prevActivities,
      [id]: value,
    }));

    setXC((prevLanguages) =>
      prevLanguages.map((ExtracurricularsInput, i) =>
        i === index
          ? { ...ExtracurricularsInput, levelParticipated: value }
          : ExtracurricularsInput
      )
    );
  };

  const [rowCount, setRowCount] = useState<number>(1);
  const [selectedLevels, setSelectedLevels] = useState<string[]>(
    Array(rowCount).fill("")
  );

  const handleAddRow = (event: React.MouseEvent) => {
    event.preventDefault();
    setRowCount((prevRowCount) => prevRowCount + 1);
    setSelectedLevels((prevLevels) => [...prevLevels, ""]);

    setXC((prevLanguages) => [
      ...prevLanguages,
      {
        usersID: userID,
        activityType: "",
        leadershipLvl: "",
        levelParticipated: "",
      },
    ]);
  };

  const handleRemoveRow = (event: React.MouseEvent) => {
    event.preventDefault();
    if (rowCount > 1) {
      setRowCount((prevRowCount) => prevRowCount - 1);
      setSelectedLevels((prevLevels) => prevLevels.slice(0, -1));

      // Remove the last entry from selectedActivity
      const newSelectedActivity = { ...selectedActivity };
      delete newSelectedActivity[`participationLevel${rowCount - 1}`];
      setSelectedActivities(newSelectedActivity);

      // Remove the last row from xC
      setXC((prevLanguages) => prevLanguages.slice(0, -1));
    }
  };

  const handleSaveData = async (event: React.MouseEvent) => {
    event.preventDefault(); // Prevent the default form submission behavior

    // Use Promise.all to wait for all asynchronous calls to complete

    await Promise.all(
      xC.map((rowData, index) => createNewExtraCurricula(index, userID))
    );

    // Additional logic after all calls have completed, if needed
  };

  useEffect(() => {
    setUserID(userId);
  }, [userId]);
  return (
    <fieldset className="pb-200">
      <h2 className="text-base font-semibold leading-7 text-gray-900">
        Extracirriculars/Sports
      </h2>
      <p className="mt-1 text-sm leading-6 text-gray-600">
        Input data from your extracirricular activity history.
      </p>
      <div className="flex space-x-2">
        <Button
          onClick={handleAddRow}
          className="px-2 py-1 bg-green-700 text-white rounded-md hover:bg-green-800"
        >
          Add Activity
        </Button>
        <button
          onClick={handleRemoveRow}
          className="px-2 py-1 bg-red-500 text-white rounded-md hover:bg-red-600"
        >
          Remove Activity
        </button>
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Sport/Activity</TableHead>
            <TableHead>Participation Level</TableHead>
            <TableHead>Leadership Role</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {[...Array(rowCount)].map((_, index) => (
            <TableRow key={index}>
              <TableCell className="font-medium">
                <Input
                  id={`proficiency${index}`}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleActivityTypeChange(index, e.target.value)
                  }
                />
              </TableCell>
              <TableCell>
                <ComboBoxTwo
                  index={index} // Pass the index to the Combobox
                  id={`participationLevel${index}`}
                  options={participationLevels}
                  value={selectedActivity[`participationLevel${index}`] || ""}
                  onChange={(id: string, index: number, value: string) =>
                    handleLevelParticipatedChange(index, id, value)
                  } // Adjust the onChange to pass index
                  placeholder="Activity level"
                />
              </TableCell>
              <TableCell>
                <Input
                  id={`proficiency${index}`}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleLeadershipChange(index, e.target.value)
                  }
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className="flex justify-end">
        <button
          onClick={handleSaveData} //TODO: Add mutation
          className="px-2 py-1 bg-green-500 text-white rounded-md hover:bg-green-600"
        >
          Save activity info
        </button>
      </div>
    </fieldset>
  );
};

export default Extracurriculars;
