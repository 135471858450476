import React, { useState, useEffect, ChangeEvent } from "react";

import { generateClient } from "aws-amplify/api";
import { InputStateAthletic } from "../constants/interface";
import { UserIdProps} from "../constants/props";
import { createPhysicalScreeningtest } from "../graphql/mutations";
import { Input } from "./elements/input";
import { Button } from "./elements/button";
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "./elements/table";
import { ComboBoxTwo } from "./elements/comboBoxTwo";

import TimeInput from "./elements/timeInput";

const AthleteInfo: React.FC<UserIdProps> = ({ userId }) => {
  const client = generateClient();
  const [userID, setUserID] = useState<string>("");

  const [athleticHistory, setAthleticHistory] = useState<InputStateAthletic[]>([
    // Default state for the first row
    {
      testDate: "",
      TestAdministrator: "",
      SwimTime: "00",
      RunTime: "00",
      pushUps: 0,
      sitUps: 0,
      pullUps: 0, 
      usersID: "",
      measureHeight: 0,
      measureWeight: 0,
      DOB: "",
    },
  ]);

  const [selectedRunMin, setSelectedRunMin] = useState<{ [id: string]: string }>({
    "defaultRunMin": "0"
  });
  
  const [selectedRunSec, setSelectedRunSec] = useState<{ [id: string]: string }>({
    "defaultRunSec": "0"
  });
  
  const [selectedSwimMin, setSelectedSwimMin] = useState<{ [id: string]: string }>({
    "defaultSwimMin": "0"
  });
  
  const [selectedSwimSec, setSelectedSwimSec] = useState<{ [id: string]: string }>({
    "defaultSwimSec": "0"
  });
  

  const [rowCount, setRowCount] = useState<number>(1);
 
  const createNewAtheleticHistory = async (index: number, usersID: string) => {
    try {
      const newPhysicalScreeningtest = await client.graphql({
        query: createPhysicalScreeningtest,
        variables: {
          input: {
            testDate: athleticHistory[index].testDate,
            TestAdministrator: athleticHistory[index].TestAdministrator,
            SwimTime: athleticHistory[index].SwimTime,
            RunTime: athleticHistory[index].RunTime,
            pushUps: athleticHistory[index].pushUps,
            sitUps: athleticHistory[index].sitUps,
            pullUps: athleticHistory[index].pullUps,
            usersID: userID,
          
            compositeScore: 0
          },
        },
      });
      console.log(newPhysicalScreeningtest);
    }
    catch (error) {
      console.error("Error creating new athleticHistory:", error);
    }
  };
  const handleAddRow = (event: React.MouseEvent) => {
    event.preventDefault();
    setRowCount((prevRowCount) => prevRowCount + 1);
  
    setAthleticHistory((prevAthletic) => [
      ...prevAthletic,
      {
        testDate: "",
        TestAdministrator: "",
        SwimTime: "",
        RunTime: "",
        pushUps: 0,
        sitUps: 0,
        pullUps: 0, 
        usersID: "",
        measureHeight: 0,
        measureWeight: 0,
        DOB: ""
    
      },
    ]);
  };

  const handleRemoveRow = (event: React.MouseEvent) => {
    event.preventDefault();
    if (rowCount > 1) {
      setRowCount((prevRowCount) => prevRowCount - 1);

      // Remove the last entry from selectedActivity
      const newRunMin = { ...selectedRunMin };
      delete newRunMin[`RunMin${rowCount - 1}`];
      setSelectedRunMin(newRunMin);

      const newRunSec = { ...selectedRunSec };
      delete newRunSec[`RunMin${rowCount - 1}`];
      setSelectedRunSec(newRunSec);

      const newSwimMin = { ...selectedSwimMin };
      delete newSwimMin[`RunMin${rowCount - 1}`];
      setSelectedSwimMin(newSwimMin);

      const newSwimSec = { ...selectedSwimSec };
      delete newSwimSec[`RunMin${rowCount - 1}`];
      setSelectedSwimMin(newSwimSec);

      setAthleticHistory((prevAthletic) => prevAthletic.slice(0, -1));
    }
  };
  
  const handleInputChange = (index: number, property: string, value: string) => {
    setAthleticHistory((prevAthletic) =>
    prevAthletic.map((AthleticInput, i) =>
        i === index
          ? { ...AthleticInput, [property]: value }
          : AthleticInput
      )
    );
  };
  
  
  const handleTimeInputChange = (
    index: number,
    minutes: string,
    seconds: string,
    type: "run" | "swim"
  ) => {
    setAthleticHistory((prevAthletic) =>
      prevAthletic.map((AthleticInput, i) => {
        if (i === index) {
          const timeValue = `${minutes}:${seconds}`;
          return {
            ...AthleticInput,
            RunTime: type === "run" ? timeValue : AthleticInput.RunTime,
            SwimTime: type === "swim" ? timeValue : AthleticInput.SwimTime,
          };
        }
        return AthleticInput;
      })
    );
  
    if (type === "run") {
      setSelectedRunMin((prevRunMin) => ({
        ...prevRunMin,
        [`RunMin${index}`]: minutes,
      }));
  
      setSelectedRunSec((prevRunSec) => ({
        ...prevRunSec,
        [`RunSec${index}`]: seconds,
      }));
    } else if (type === "swim") {
      setSelectedSwimMin((prevSwimMin) => ({
        ...prevSwimMin,
        [`SwimMin${index}`]: minutes,
      }));
  
      setSelectedSwimSec((prevSwimSec) => ({
        ...prevSwimSec,
        [`SwimSec${index}`]: seconds,
      }));
    }
  };
  
  

  const handleSaveData = async (event: React.MouseEvent) => {
    event.preventDefault(); // Prevent the default form submission behavior

    // Use Promise.all to wait for all asynchronous calls to complete

    await Promise.all(
      athleticHistory.map((rowData, index) => createNewAtheleticHistory(index, userID))
    );

    // Additional logic after all calls have completed, if needed
  };
useEffect(() => {
    setUserID(userId);
  });
  return (
    <div className="pb-200">
      <h2 className="text-base font-semibold leading-7 text-gray-900">
        PST Scores
      </h2>
      <p className="mt-1 text-sm leading-6 text-gray-600">
        Input data from your prior professional jobs.
      </p>
      <div className="flex space-x-2">
        <Button
          onClick={handleAddRow}
          className="px-2 py-1 bg-green-700 text-white rounded-md hover:bg-green-800"
        >
          Add PST Score
        </Button>
        <button
          onClick={handleRemoveRow}
          className="px-2 py-1 bg-red-500 text-white rounded-md hover:bg-red-600"
        >
          Remove PST Score
        </button>
      </div>
      <Table>
        <TableCaption>A list of your PST Scores</TableCaption>
        <TableHeader>
          <TableRow>
           
          </TableRow>
        </TableHeader>
        <TableBody>
          {[...Array(rowCount)].map((_, index) => (
            <React.Fragment key={index}>
               <TableRow>
                {/* Additional headers for the next row with colspan */}
                
                <TableHead>PST Date</TableHead>
                <TableHead>PST Administrator</TableHead>
                <TableHead>Run</TableHead>
                <TableHead>Swim</TableHead>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">
                  <Input
                    id={`testDate${index}`}
                    type="date"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(index, "testDate", e.target.value)}
                  />
                </TableCell>
                <TableCell>
                  <Input
                    id={`TestAdministrator${index}`}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(index, "TestAdministrator", e.target.value)}
                  />
                </TableCell>
                <TableCell>
                <TimeInput
                    minutes={selectedRunMin[`RunMin${index}`] || ""}
                    seconds={selectedRunSec[`RunSec${index}`] || ""}
                    onChange={(m, s) => handleTimeInputChange(index, m, s, "run")}
                  />
                </TableCell>
                <TableCell>
                <TimeInput
  minutes={selectedSwimMin[`SwimMin${index}`] || ""}
  seconds={selectedSwimSec[`SwimSec${index}`] || ""}
  onChange={(m, s) => handleTimeInputChange(index, m, s, "swim")} // Pass "swim" as the type
/>

                </TableCell>
              </TableRow>
              <TableRow>
                {/* Additional headers for the next row with colspan */}
                
                <TableHead>PushUps</TableHead>
                <TableHead>Situps</TableHead>
                <TableHead>Pullups</TableHead>
              </TableRow>
              <TableRow>
                {/* Additional inputs for the next row */}
                <TableCell colSpan={1}>
                  <Input
                    id={`pushUps${index}`}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(index, "pushUps", e.target.value)}
                  />
                </TableCell>
                <TableCell>
                  <Input
                    id={`sitUps${index}`}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(index, "sitUps", e.target.value)}
                  />
                </TableCell>
                <TableCell>
                  <Input
                    id={`pullUps${index}`}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(index, "pullUps", e.target.value)}
                  />
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
      <div className="flex justify-end">
        <button
          onClick={handleSaveData} //TODO: Add mutation
          className="px-2 py-1 bg-green-500 text-white rounded-md hover:bg-green-600"
        >
          Save PST Scores
        </button>
      </div>
    </div>
  );
};

export default AthleteInfo;
