import styled,{css} from "styled-components";
import './LoginStyles.css';

import './LoginStyles.css';
import '../fonts/Liberator-Heavy.ttf';
import '../fonts/Liberator-Medium.ttf';
import '../fonts/Liberator-Light.ttf';


import myImage from '../assets/operator2.jpg';




export const MainContainer = styled.div `
    background-color: rgb(255,255,255);
    /* padding-top: 30px;
    padding-bottom:  30px; */
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;

        align-items: center;
    flex-direction: row;
`



export const ImageLeft = styled.div`
  width: 50%;
  height: 100%;
  background-image: url(${myImage});
`;
export const BoxL = styled.div `
background-color: rgb(255,255,255);
position: relative;
display: flex;
align-items: center;
justify-content: center;
overflow: hidden;
width: 60%;
height: 100%;
font-family: "RLight2";
flex-direction: column;

`

export const BoxR = styled.div `
background-color: rgb(255,255,255);
display: flex;
flex-direction: column;

align-items: top;
width: 40%;
height: 100%;


`

export const OperatorImage = styled.img`
align-self: right;
background-color: rgb(255,255,255);

max-height:100%;
max-width:100%;


`

export const Title = styled.p`
position: absolute;
color: white; /* Adjust text color as needed */
text-align: center;
font-family: "RLight";
font-size: 50px;
`;


export const TimeLineTitle = styled.p`
text-align: left;
align-self: flex-start; /* You can also use 'start' */
justify-content: flex-start; /* You can also use 'start' */
color: black; /* Adjust text color as needed */
font-family: "RLight";
font-size: 30px;
`;